var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",style:({ 'margin-top': _vm.statusBarHeight })},[_c('nav-header'),_c('main',[(_vm.activeCases.length != 1)?_c('swiper',{staticClass:"reservation-card-group",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.activeCases),function(activeCase){return _c('swiper-slide',{key:activeCase.id},[_c('div',{staticClass:"reservation-card"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"doctor-name"},[(activeCase.type === 'video')?_c('i',{staticClass:"video-icon"}):_c('i',{staticClass:"text-icon"}),_c('span',[_vm._v(_vm._s(activeCase.doctorName))])]),_c('div',{staticClass:"status-tag"},[_vm._v(" "+_vm._s(activeCase.statusName)+" ")])]),(activeCase.type === 'video')?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(activeCase.date)+" ")]):_c('div',{staticClass:"content"},[_c('div',[_vm._v(_vm._s(activeCase.date[0]))]),_c('div',[_vm._v(_vm._s(activeCase.date[1]))])]),_c('div',{staticClass:"btn-group"},[(activeCase.status >= 15)?_c('router-link',{attrs:{"to":{
                name: 'caseDetail',
                params: { id: activeCase.id },
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("詳情")])]}}],null,true)}):_c('router-link',{attrs:{"to":{
                name: 'reservationDetail',
                params: { id: activeCase.id },
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("詳情")])]}}],null,true)}),(activeCase.roomNumber && activeCase.type === 'video')?_c('router-link',{attrs:{"to":{
                name: 'videoClinic',
                params: { roomNumber: activeCase.roomNumber },
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("進入問診室")])]}}],null,true)}):(activeCase.roomNumber && activeCase.type === 'text')?_c('router-link',{attrs:{"to":{
                name: 'messageClinic',
                params: { roomNumber: activeCase.roomNumber },
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("進入問診室")])]}}],null,true)}):_c('button',[_vm._v("請等待")])],1)])])}),1):_c('div',{staticClass:"reservation-card single"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"doctor-name"},[(_vm.activeCases[0].type === 'video')?_c('i',{staticClass:"video-icon"}):_c('i',{staticClass:"text-icon"}),_c('span',[_vm._v(_vm._s(_vm.activeCases[0].doctorName))])]),_c('div',{staticClass:"status-tag"},[_vm._v(" "+_vm._s(_vm.activeCases[0].statusName)+" ")])]),(_vm.activeCases[0].type === 'video')?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.activeCases[0].date)+" ")]):_c('div',{staticClass:"content"},[_c('div',[_vm._v(_vm._s(_vm.activeCases[0].date[0]))]),_c('div',[_vm._v(_vm._s(_vm.activeCases[0].date[1]))])]),_c('div',{staticClass:"btn-group"},[(_vm.activeCases[0].status >= 15)?_c('router-link',{attrs:{"to":{
            name: 'caseDetail',
            params: { id: _vm.activeCases[0].id },
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("詳情")])]}}],null,false,1989583720)}):_c('router-link',{attrs:{"to":{
            name: 'reservationDetail',
            params: { id: _vm.activeCases[0].id },
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("詳情")])]}}])}),(
            _vm.activeCases[0].isDoctorLogined == 1 && _vm.activeCases[0].isVideoEnd
          )?_c('router-link',{attrs:{"to":{
            name: 'videoClinic',
            params: { roomNumber: _vm.activeCases[0].roomNumber },
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("進入問診室")])]}}],null,false,335280568)}):(
            _vm.activeCases[0].roomNumber &&
            _vm.activeCases[0].type === 'video' &&
            _vm.activeCases[0].isTimes == true
          )?_c('router-link',{attrs:{"to":{
            name: 'videoClinic',
            params: { roomNumber: _vm.activeCases[0].roomNumber },
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("進入問診室")])]}}])}):(
            _vm.activeCases[0].roomNumber && _vm.activeCases[0].type === 'text'
          )?_c('router-link',{attrs:{"to":{
            name: 'messageClinic',
            params: { roomNumber: _vm.activeCases[0].roomNumber },
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("進入問診室")])]}}])}):_c('button',{on:{"click":_vm.showWait}},[_vm._v("請等待")])],1)]),_c('div',{staticClass:"past-cases"},[_c('div',{staticClass:"tool-bar"},[_c('div',{staticClass:"type-selection"},[_c('button',{class:{ active: _vm.selectedType === 'video' },on:{"click":function($event){_vm.selectedType = 'video'}}},[_vm._v(" 視像問診 ")]),_c('button',{class:{ active: _vm.selectedType === 'text' },on:{"click":function($event){_vm.selectedType = 'text'}}},[_vm._v(" 訊息問診 ")])]),_c('el-select',{attrs:{"placeholder":"請選擇時間"},on:{"change":function($event){return _vm.getData(_vm.selectedType, $event)}},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}},_vm._l((_vm.monthList),function(month){return _c('el-option',{key:month.value,attrs:{"value":month.value,"label":month.label,"placeholder":""}})}),1)],1),_c('div',{staticClass:"case-list"},_vm._l((_vm.caseList),function(caseRecord){return _c('div',{key:caseRecord.id,staticClass:"case-card"},[_c('div',{staticClass:"title"},[(_vm.selectedType === 'video')?_c('i',{staticClass:"video-icon"}):_c('i',{staticClass:"text-icon"}),_c('span',[_vm._v(_vm._s(caseRecord.doctorName))])]),_c('div',{staticClass:"content"},[(_vm.selectedType === 'video')?_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(caseRecord.date)+" ")]):_c('div',{staticClass:"time"},[_c('div',[_vm._v(_vm._s(caseRecord.date[0]))]),_c('div',[_vm._v(_vm._s(caseRecord.date[1]))])]),(caseRecord.status >= 15)?_c('router-link',{attrs:{"to":{
                name: 'caseDetail',
                params: { id: caseRecord.id },
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("詳情")])]}}],null,true)}):_c('router-link',{attrs:{"to":{
                name: 'reservationDetail',
                params: { id: caseRecord.id },
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v("詳情")])]}}],null,true)})],1)])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }