<template>
  <div class="container" :style="{ 'margin-top': statusBarHeight }">
    <nav-header></nav-header>
    <!-- 这两部分不知道能不能合成一块 -->
    <!-- 当有两个状态为进行中（status != [12, 36, 40, 33]）的-->
    <!-- 案件的时候，使用swiper进行显示（可以左右滑动） -->
    <main>
      <swiper
        v-if="activeCases.length != 1"
        :options="swiperOption"
        class="reservation-card-group"
      >
        <swiper-slide v-for="activeCase in activeCases" :key="activeCase.id">
          <div class="reservation-card">
            <div class="title">
              <div class="doctor-name">
                <i v-if="activeCase.type === 'video'" class="video-icon"></i>
                <i v-else class="text-icon"></i>
                <span>{{ activeCase.doctorName }}</span>
              </div>
              <div class="status-tag">
                {{ activeCase.statusName }}
              </div>
            </div>
            <div class="content" v-if="activeCase.type === 'video'">
              {{ activeCase.date }}
            </div>
            <div class="content" v-else>
              <div>{{ activeCase.date[0] }}</div>
              <div>{{ activeCase.date[1] }}</div>
            </div>
            <div class="btn-group">
              <router-link
                :to="{
                  name: 'caseDetail',
                  params: { id: activeCase.id },
                }"
                custom
                v-slot="{ navigate }"
                v-if="activeCase.status >= 15"
              >
                <button @click="navigate">詳情</button>
              </router-link>
              <router-link
                :to="{
                  name: 'reservationDetail',
                  params: { id: activeCase.id },
                }"
                custom
                v-slot="{ navigate }"
                v-else
              >
                <button @click="navigate">詳情</button>
              </router-link>

              <router-link
                v-if="activeCase.roomNumber && activeCase.type === 'video'"
                :to="{
                  name: 'videoClinic',
                  params: { roomNumber: activeCase.roomNumber },
                }"
                custom
                v-slot="{ navigate }"
              >
                <button @click="navigate">進入問診室</button>
              </router-link>
              <router-link
                v-else-if="activeCase.roomNumber && activeCase.type === 'text'"
                :to="{
                  name: 'messageClinic',
                  params: { roomNumber: activeCase.roomNumber },
                }"
                custom
                v-slot="{ navigate }"
              >
                <button @click="navigate">進入問診室</button>
              </router-link>
              <button v-else>請等待</button>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 当只有一个的时候，不使用swiper，为了两端对齐 -->
      <div v-else class="reservation-card single">
        <div class="title">
          <div class="doctor-name">
            <i v-if="activeCases[0].type === 'video'" class="video-icon"></i>
            <i v-else class="text-icon"></i>
            <span>{{ activeCases[0].doctorName }}</span>
          </div>
          <div class="status-tag">
            {{ activeCases[0].statusName }}
          </div>
        </div>
        <div class="content" v-if="activeCases[0].type === 'video'">
          {{ activeCases[0].date }}
        </div>
        <div class="content" v-else>
          <div>{{ activeCases[0].date[0] }}</div>
          <div>{{ activeCases[0].date[1] }}</div>
        </div>
        <div class="btn-group">
          <router-link
            :to="{
              name: 'caseDetail',
              params: { id: activeCases[0].id },
            }"
            custom
            v-slot="{ navigate }"
            v-if="activeCases[0].status >= 15"
          >
            <button @click="navigate">詳情</button>
          </router-link>
          <router-link
            :to="{
              name: 'reservationDetail',
              params: { id: activeCases[0].id },
            }"
            custom
            v-slot="{ navigate }"
            v-else
          >
            <button @click="navigate">詳情</button>
          </router-link>
          <router-link
            v-if="
              activeCases[0].isDoctorLogined == 1 && activeCases[0].isVideoEnd
            "
            :to="{
              name: 'videoClinic',
              params: { roomNumber: activeCases[0].roomNumber },
            }"
            custom
            v-slot="{ navigate }"
          >
            <button @click="navigate">進入問診室</button>
          </router-link>
          <router-link
            v-else-if="
              activeCases[0].roomNumber &&
              activeCases[0].type === 'video' &&
              activeCases[0].isTimes == true
            "
            :to="{
              name: 'videoClinic',
              params: { roomNumber: activeCases[0].roomNumber },
            }"
            custom
            v-slot="{ navigate }"
          >
            <button @click="navigate">進入問診室</button>
          </router-link>
          <router-link
            v-else-if="
              activeCases[0].roomNumber && activeCases[0].type === 'text'
            "
            :to="{
              name: 'messageClinic',
              params: { roomNumber: activeCases[0].roomNumber },
            }"
            custom
            v-slot="{ navigate }"
          >
            <button @click="navigate">進入問診室</button>
          </router-link>
          <button v-else @click="showWait">請等待</button>
        </div>
      </div>
      <div class="past-cases">
        <div class="tool-bar">
          <div class="type-selection">
            <button
              @click="selectedType = 'video'"
              :class="{ active: selectedType === 'video' }"
            >
              視像問診
            </button>
            <button
              @click="selectedType = 'text'"
              :class="{ active: selectedType === 'text' }"
            >
              訊息問診
            </button>
          </div>
          <el-select
            placeholder="請選擇時間"
            @change="getData(selectedType, $event)"
            v-model="selectedMonth"
          >
            <el-option
              v-for="month in monthList"
              :key="month.value"
              :value="month.value"
              :label="month.label"
              placeholder=""
            ></el-option>
          </el-select>
        </div>
        <div class="case-list">
          <div
            class="case-card"
            v-for="caseRecord in caseList"
            :key="caseRecord.id"
          >
            <div class="title">
              <i v-if="selectedType === 'video'" class="video-icon"></i>
              <i v-else class="text-icon"></i>
              <span>{{ caseRecord.doctorName }}</span>
            </div>
            <div class="content">
              <div v-if="selectedType === 'video'" class="time">
                {{ caseRecord.date }}
              </div>
              <div v-else class="time">
                <div>{{ caseRecord.date[0] }}</div>
                <div>{{ caseRecord.date[1] }}</div>
              </div>
              <router-link
                :to="{
                  name: 'caseDetail',
                  params: { id: caseRecord.id },
                }"
                custom
                v-slot="{ navigate }"
                v-if="caseRecord.status >= 15"
              >
                <button @click="navigate">詳情</button>
              </router-link>
              <router-link
                :to="{
                  name: 'reservationDetail',
                  params: { id: caseRecord.id },
                }"
                custom
                v-slot="{ navigate }"
                v-else
              >
                <button @click="navigate">詳情</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { casesList } from "@/api";
import navHeader from "../../components/navHeader.vue";
export default {
  components: { navHeader },
  data: function () {
    return {
      statusBarHeight: "0px",
      caseList: [],
      monthList: [],
      selectedMonth: "",
      selectedType: "video",
      swiperOption: {
        slidesPerView: 1.2,
        spaceBetween: 16,
        centeredSlides: true,
      },
      activeCases: [],
    };
  },
  methods: {
    showWait() {
      this.$message({ message: "問診室已關閉或未開放" });
    },
    getData(type, month) {
      this.caseList = [];
      let typeId;
      if (type) typeId = type === "video" ? 1 : 2;
      else typeId = this.selectedType === "video" ? 1 : 2;
      let data = {
        pageSize: 1000,
        type_id: typeId,
        appointment_month: month,
      };
      let monthList = [];
      casesList(data).then((res) => {
        function pushToMonthList(date) {
          let month = date.split("-")[1];
          let year = date.split("-")[0];
          if (!monthList.includes(`${year}-${month}`))
            monthList.push(`${year}-${month}`);
        }
        res.data.dataList.forEach((e) => {
          let date;
          if (e.type_id === 1) {
            pushToMonthList(e.appointment_date);
            date = this.$time.appointmentToFullDate(
              e.appointment_date,
              e.appointment_time
            );
          } else {
            pushToMonthList(e.created_at);
            date = [
              `開始時間：${this.$time.appointmentToFullDate(
                e.appointment_date,
                e.appointment_time
              )}`,
              `結束時間：${this.$time.appointmentToFullDate(
                e.appointment_close_date,
                e.appointment_time
              )}`,
            ];
            // date = this.$time.createdAtToFullDate(e.created_at);
          }
          this.caseList.push({
            id: e.id,
            doctorName: e.doctor.name,
            status: e.status,
            isDoctorLogined: e.chatroom == null ? 0 : e.chatroomdoctor_logined,
            isVideoEnd: e.chatroom == null ? 0 : e.chatroom.status,
            date,
            isTimes:
              new Date() >=
              new Date(
                e.appointment_date.replace(/-/g, "/") +
                  " " +
                  e.appointment_time.split("-")[0]
              )
                ? (this.isTimes = true)
                : (this.isTimes = false), //進行添加是否時間到了狀態
          });
        });

        if (!this.selectedMonth) {
          this.monthList = [];
          monthList.map((e) => {
            this.monthList.push({
              label:
                parseInt(e.split("-")[0]) +
                "年" +
                parseInt(e.split("-")[1]) +
                "月",
              value: e,
            });
          });
          if (this.monthList.length === 0) {
            this.monthList.push({
              label: "無數據",
              value: "無數據",
            });
          }
        }
      });
    },
  },
  watch: {
    selectedType: function (v) {
      this.selectedMonth = "";
      this.getData(v, this.selectedMonth);
    },
  },
  created() {
    // ios头部内容适配问题
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight + "px";
    }
  },
  mounted: function () {
    this.getData(this.selectedType, this.selectedMonth);
    casesList({
      pageSize: 1000,
      consultation: 2,
    }).then((res) => {
      res.data.dataList.forEach((e) => {
        let roomNumber = e.chatroom ? e.chatroom.room_number : "";
        let date;
        if (e.type_id === 1)
          date = this.$time.appointmentToFullDate(
            e.appointment_date,
            e.appointment_time
          );
        else
          date = [
            `開始時間：${this.$time.appointmentToFullDate(
              e.appointment_date,
              e.appointment_time
            )}`,
            `結束時間：${this.$time.appointmentToFullDate(
              e.appointment_close_date,
              e.appointment_time
            )}`,
          ];
        this.activeCases.push({
          id: e.id,
          doctorName: e.doctor.name,
          statusName: e.status_name,
          roomNumber,
          date,
          status: e.status,
          isDoctorLogined: e.chatroom == null ? 0 : e.chatroomdoctor_logined,
          isVideoEnd: e.chatroom == null ? 0 : e.chatroom.status,
          type: e.type_id === 1 ? "video" : "text",
          isTimes:
            new Date() >=
            new Date(
              e.appointment_date.replace(/-/g, "/") +
                " " +
                e.appointment_time.split("-")[0]
            )
              ? (this.isTimes = true)
              : (this.isTimes = false), //進行添加是否時間到了狀態
        });
      });
    });
  },
};
</script>

<style lang="scss" scoped>
main {
  padding-top: 5vh;
}
.container {
  background-color: $blue-50;
  min-height: 100vh;
  border-top: var(--safe-top) solid white;
  padding-bottom: var(--safe-bottom);
}
.reservation-card {
  background-color: $green-300;
  color: white;
  @include flexc-start-stretch;
  padding: 16px;
  margin: 16px 0;
  &.single {
    margin: 16px;
  }
  .title {
    @include flexr-between-center;
    .doctor-name {
      font-size: 18px;
      font-weight: $semi-bold;
      @include flexr-center-center;
    }
    .status-tag {
      font-size: 12px;
      background-color: $white;
      color: $black;
      padding: 4px 8px;
    }
    margin-bottom: 8px;
  }
  .content {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .btn-group {
    @include flexr-around-center;
    button {
      flex-grow: 1;
      width: 40%;
      color: $blue-300;
      padding: 8px;
      background-color: white;
      font-size: 12px;
    }
    button:first-of-type {
      margin-right: 16px;
    }
  }
}
.past-cases {
  padding: 16px;
  .type-selection {
    @include flexr-start-center;
    button {
      font-size: 16px;
      color: $green-300;
      font-weight: $extra-light;
      margin-right: 8px;
      background-color: transparent;
    }
    button.active {
      font-weight: $semi-bold;
    }
    margin-bottom: 8px;
  }
  .case-card {
    background-color: $green-300;
    color: white;
    padding: 16px;
    margin-bottom: 16px;
    .title {
      font-size: 18px;
      font-weight: $semi-bold;
      @include flexr-start-center;
    }
    .content {
      @include flexr-between-end;
      font-size: 12px;
      button {
        font-size: 12px;
        padding: 4px 24px;
        color: $blue-225;
        background-color: white;
      }
      .time {
        margin-top: 8px;
      }
    }
  }
}
.video-icon,
.text-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background-size: cover;
}
.video-icon {
  background-image: url("../../assets/img/video_icon.png");
}
.text-icon {
  background-image: url("../../assets/img/im_icon.png");
}
.el-select::v-deep {
  .el-input input.el-input__inner,
  .el-input.is-focus input.el-input__inner {
    border-color: rgba($color: $black, $alpha: 0.3);
  }
}
</style>